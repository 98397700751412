<template>
  <b-container v-if="paginatedItems">
    <b-row>
      <b-col
        v-for="(item, index) in paginatedItems"
        :key="index"
        cols="12"
        sm="4"
        class="my-1"
      >
        <b-card
          bg-variant="info"
          header-variant="card_header"
          text-variant="white"
          :header="item.title"
          style="height: 900px"
          class="text-center"
        >
        <template #header>
          <h4 class="mb-0">
            {{ item.title }}
          </h4>
        </template>
          <p v-if="item.dek" class="card-text">
            {{ decoder(item.dek.replace(/(<([^>]+)>)/ig, "")) }}
          </p>
          <b-img 
            v-bind:src="item.hero_image.url"
            height="300px"
            width="300px"
            />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        @change="onPageChanged"
        first-number
        last-number
        align="center"
        class="my-0"
      />
    </b-row>
  </b-container>
  <b-row class="justify-content-md-center" v-else>
    <b-icon
      icon="arrow-clockwise"
      animation="spin"
      font-scale="4"
      style="color: white;"
    />
  </b-row>
</template>

<script>
import axios from 'axios';

export default {
  name: "HeroStories",
  data() {
    return {
      apiURL: "https://cryptodire.kontinentalist.com/api/v1/stories",
      paginatedItems: null,
      currentPage: 1,
      perPage: null,
      totalRows: null
    };
  },
  mounted() {
    this.paginate(this.currentPage);
  },
  methods: {
    decoder(dek) {
      var dekTextarea = document.createElement('textarea');
      dekTextarea.innerHTML = dek;
      return dekTextarea.value;
    },
    async paginate(page) {
      await axios
        .get(`${this.apiURL}?page=${page}`)
        .then(response => {
          this.currentPage = response.data.current_page;
          this.perPage = response.data.per_page;
          this.totalRows = response.data.total;
          this.paginatedItems = response.data.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    onPageChanged(page) {
      this.paginate(page);
    }
  }
};
</script>

<style scoped>
</style>