<template>
  <div id="NotFound">
    <h1>Error 404 - Page Not Found.</h1>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>
